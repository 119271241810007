<template>
   <div class="house-list-wrapper">
       <div class="flex-box">
            <div class="hd">
                <van-nav-bar left-arrow safe-area-inset-top title="我的分享" @click-left="back"></van-nav-bar>
                 <van-search v-model="keyword" placeholder="请输入楼盘名称" @search="searchHouse" ref="recordListSearch"/>
            </div>
            <div class="bd" ref="houseListScrollView">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="100"
                    @load="onLoad"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    >
                        <div class="house-item border_bottom" v-for="(item,index) in houseList" :key="index" @click="gotoDetail(item.id)">    
                            <div class="house-item-thumb">
                                <img :alt="item.name" v-lazy="item.logo">
                                <div class="video-icon p-a-m-c" v-if="item.buildingVideo"><van-icon name="system-icon_play" /></div>
                                <div class="addr" v-if="item.cityname">{{item.cityname}}</div>
                                <div class="vr-icon" v-if="item.buildingVr"><van-icon name="system-vr-2" /></div>
                            </div>
                            <div class="house-item-ctn">
                                <p class="house-name">{{item.name}}</p>
                                <p class="house-price">{{
                                    item.priceTotal ? item.priceTotal+'万/套'
                                    : item.priceAvg ? item.priceAvg+'元/㎡' : '暂无'
                                    }}</p>
                            </div>
                        </div>
                    </van-list>
                    </van-pull-refresh>
            </div>
       </div>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import api from "@/api";
export default {

    mixins:[backMixin],


    data(){

        return{

            keyword:"",

            //是否到底

            finished:false,

            //是否加载

            loading:false,

            //是否下拉结束

            refreshing:false,

            //是否报错

            error:false,

            //页码
            
            page:1,

            //一次获取的数量

            size:8,

            //楼盘列表

            houseList:[]

        }

    },

    computed:{
        
        loginReady(){

            return this.$store.state.userInfo?.ready;

        }
        
    },

    methods: {

        onLoad() {

            this.getHouseData();

        },

        //搜索楼盘数据

        searchHouse(){

            this.page=1;

            this.$toast.loading({

                message: '正在加载...',

                forbidClick: true,

                duration:0

            });

            let options = {

                    number:this.page,

                    size:this.size,

                    keyword:this.keyword,

            }

            options = Object.assign(options,this.searchForm);

            //重置一下

            api.getUserShareHouseStatistics({

                options:options,

                success:(res)=>{

                    if(res.type == 200){

                        this.houseList=res.data.content;

                        this.$set(this,'houseList',res.data.content);

                        this.error=false;

                        this.finished=res.data.last;

                    }else{

                        this.error = true;

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.error=true;

                },

                complete: (res)=> {

                    if(res.code && res.code !=200){

                        this.error = true;
                        
                    }

                    this.loading=false;

                    this.refreshing=false;

                    this.$toast.clear();
                    
                }


            })



        },

        //获取楼盘数据

        getHouseData(type){

            let options = {

                page:this.page,

                pageSize:this.size,

                keyword:this.keyword,

            }

            options = Object.assign(options,this.searchForm);


            api.getUserShareHouseStatistics({

                options:options,

                success:(res)=>{

                    if(res.type == 200){

                        //首先判断是否存在勾选状态的楼盘

                        if(type == 'refresh'){

                            this.houseList = res.data.list;

                            this.$toast({

                                message:"楼盘已更新",

                                position:"top"

                            });

                        }else{

                            this.houseList.push(...res.data.list);

                        }

                        this.error=false;

                        const last = this.$listLastCheck(res.data?.list,this.size);

                        if(!last){

                            this.page++;

                        }

                        this.finished=last;

                    }else{

                        this.error = true;

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.error=true;

                },

                complete: (res)=> {

                    if(!res?.code || res?.code && res.code !=200){

                        this.error = true;

                        this.finished = false;
                        
                    }

                    this.refreshing=false;

                    this.loading=false;
                    
                }


            })


        },


        onRefresh() {

            // 清空列表数据

            this.finished = true;

            // 重新加载数据

            // 将 loading 设置为 true，表示处于加载状态

            this.loading = true;

            this.page =1 ;

            this.getHouseData('refresh');

        },

        //跳转

        gotoDetail(id){

            //每次跳转都设置一次跳转内容

            this.$route.meta.scrollTop=this.$refs.houseListScrollView.scrollTop;

            this.$router.push({path:`/my/share/detail/${id}`});

        },

        //检查是否包含百分号

        housePointTeset(data){

            let reg = /%/g;

            return reg.test(data);

        }

    },

    activated(){
        
        this.$nextTick(()=>{

            if(this.$route.meta.scrollTop){

                this.$refs.houseListScrollView.scrollTop=this.$route.meta.scrollTop;

            }

        })

    },

    beforeDestroy(){

        this.$route.meta.scrollTop=0;
        
    }


}
</script>

<style lang="less">
.house-list-wrapper{

    display: flex;

    flex-direction: column;

    height:100%;

    width: 100%;

    .menu{

        border-bottom:10px solid #f6f6f6;

        .more .van-popup{

            overflow: hidden;

        }

    }

    .bd{

        flex:1;

        height:0;

        overflow-y:auto;

        .house-item{

            padding:20px 15px;

            display: flex;

    

            .house-item-thumb{

                width: 35%;

                height:90px;

                margin-right:10px;

                position: relative;

                .addr{

                    position: absolute;

                    left:0;

                    bottom:0;

                    padding: 2px 10px 3px;

                    background:rgba(0,0,0,.7);

                    color:#fff;

                }

                img{

                    width: 100%;

                    height:100%;

                }

                .video-icon{

                    width: 30px;

                    height:30px;

                    line-height:32px;

                    background: rgba(0,0,0,.4);

                    border-radius: 50%;

                    text-align: center;

                    color:#fff;

                    .van-icon{

                        font-size:14px;

                    }

                }

                .vr-icon{

                    position: absolute;

                    right:3px;

                    bottom:3px;

                    color:#fff;



                    .van-icon{

                        font-size:16px;

                        text-shadow:0 0 1px rgba(0,0,0,.4);

                    }

                }

            }

            .house-item-ctn{

                line-height:22px;

                color:#555555;

                flex:1;

                width: 0;

                display: flex;

                flex-direction: column;

                .house-name{

                    font-size:16px;

                }

                .house-price{

                    font-size:13px;

                }

                .house-type{

                    span{

                        padding-right:5px;

                        margin-left:5px;

                        position: relative;

                        &:after{

                            content:'';

                            position: absolute;

                            right:0;

                            top:45%;

                            width: 1px;

                            height:60%;

                            background: #eee;

                            transform: translateY(-50%);

                        }

                        &:first-child{

                            margin-left:0;

                        }

                        &:last-child:after{

                            display: none;

                        }

                    }

                }

                .house-record-text{
                    
                    padding: 5px 0;

                   span{

                       display: inline-block;

                        line-height:20px;

                    padding: 3px 5px 1px;

                    background: #f6f6f6;

                    width: auto;

                    font-size:12px;

                    color:#999999;

                   }


                }

                .house-brokerage-text{

                    padding-top:5px;
                    
                    color:#00a0e4;

                    font-size:14px;

                    letter-spacing: 1px;

                }


                

            }

        }

    }

}
</style>
